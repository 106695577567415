import {FlexDictionaryType} from '../types';
import {v5 as uuidv5} from 'uuid';

export function getKeyByValue(enumeration: FlexDictionaryType, value: any): string | null {
  for (const key in enumeration) {
    if (Object.prototype.hasOwnProperty.call(enumeration, key)&& enumeration[key] === value) {
      return key;
    }
  }
  return null;
}

export function isInEnum(value: any, enumeration: FlexDictionaryType): boolean {
  for (const key in enumeration) {
    if (enumeration[key] === value) {
      return true;
    }
  }
  return false;
}


export function isEmailValid(email: string) {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(String(email).toLowerCase());
}


const xorKey = 42; // Choose a key value to XOR against, it can be any integer value

export function obfuscateEmail(email: string): string {
  const obfuscatedEmail = email
    .split('')
    .map((char) => String.fromCharCode(char.charCodeAt(0) ^ xorKey))
    .join('');
  return obfuscatedEmail;
}

export function deobfuscateEmail(obfuscatedEmail: string): string {
  const originalEmail = obfuscatedEmail
    .split('')
    .map((char) => String.fromCharCode(char.charCodeAt(0) ^ xorKey))
    .join('');
  return originalEmail;
}


export function parseQueryString(queryString: string) {
  const searchParams = new URLSearchParams(queryString);
  const result: FlexDictionaryType = {};

  searchParams.forEach((value, key) => {
    result[key] = value;
  });

  return result;
}


export function isValidDate(date: string): boolean {
  if (!date || !date.trim()) {
    return false;
  }

  try {
    const newDate = new Date(date);
    newDate.toISOString();
    return true;
  } catch (error) {
    return false;
  }
}

export function isValidPhoneNumber(phoneNumber: string): boolean {
  // Ensure the phone number only contains digits
  if (!/^\d+$/.test(phoneNumber)) {
    return false;
  }

  // Check if the phone number has a valid length
  const length = phoneNumber.length;
  return length >= 10 && length <= 15;
}

export function normalizePhoneNumber(phoneNumber: string): string {
  // Remove non-number characters from the phone number
  return phoneNumber.replace(/[^\d]/g, '');
}

export function prependPlusIfCountryCodeExists(phoneNumber: string): string {
  // Regex pattern for country code (1-3 digits)
  const countryCodePattern = /^\d{1,3}/;

  // Remove non-numeric characters
  const numericPhoneNumber = phoneNumber.replace(/\D+/g, '');

  // Check if the number is possibly a US-based number (10 digits)
  if (numericPhoneNumber.length === 10 && !numericPhoneNumber.startsWith('1s')) {
    return '+1' + numericPhoneNumber;
  }

  // Check if the number starts with a country code
  if (countryCodePattern.test(numericPhoneNumber)) {
    return '+' + numericPhoneNumber;
  }


  return numericPhoneNumber;
}

export function toTitleCase(input: string): string {
  return (input || '')
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ').trim();
}


export function generateUniqueUserId(email: string): string {
  const namespace = uuidv5(email, uuidv5.DNS);
  const timestamp = new Date().getTime().toString();
  const uniqueId = uuidv5(timestamp, namespace);
  return uniqueId;
}

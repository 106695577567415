export enum TrackingEvents {
  NewsletterSignupModalOpened = 'Newsletter Signup Modal Opened',
  NewsletterSignupModalClosed = 'Newsletter Signup Modal Closed',
  NewsletterSignupModalUserInfoSubmitted= 'Newsletter Signup Modal User Info Submitted',
  NewsletterSignupModalSubscriptionSuccess = 'Newsletter Subscription Success',
  NewsletterSignupEmailConfirmedSuccess = 'Newsletter Signup Email Confirmed Success',
  NewsletterSignupEnterEmailInvalidEmail= 'Newsletter Signup Enter Email Invalid Email',
  NewsletterSignupEmailConfirmationUserInfoInvalidName = 'Newsletter Signup Email Confirmation User Info Invalid Name',
  NewsletterSignupEmailConfirmationUserInfoInvalidBirthday = 'Newsletter Signup Email Confirmation User Info Invalid Birthday',
  NewsletterSignupEmailConfirmationUserInfoInvalidPhoneNumber = 'Newsletter Signup Email Confirmation User Info Invalid Phone Number',
  NewsletterSignupEmailConfirmationUserInfoTextUpdatesChecked= 'Newsletter Signup Email Confirmation User Info Text Updates Checked',
  NewsletterSignupEmailConfirmationUserInfoTextUpdatesUnchecked= 'Newsletter Signup Email Confirmation User Info Text Updates Unchecked',

  }

import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {PLASMIC} from '../plasmic-init';
import {BehaviorSubject} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-root',
  template: `
  <plasmic-root-provider [loader]="plasmicLoader">
    <router-outlet></router-outlet>
  </plasmic-root-provider>
  `,
})
export class AppComponent {
  plasmicLoader = PLASMIC;
  static isBrowser = new BehaviorSubject<boolean>(false);

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    AppComponent.isBrowser.next(isPlatformBrowser(this.platformId));
  }
}

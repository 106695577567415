import {Component} from '@angular/core';

@Component({
  selector: 'health-check',
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.scss'],
})
export class HealthCheckComponent {
  title = 'Annisse Music';
}

import {Injectable} from '@angular/core';
import {AnalyticsBrowser, ID} from '@segment/analytics-next';
import {environment} from '../../environments/environment';
import {FlexDictionaryType} from '../../../types';
import {TrackingEvents} from '../../../types/analytics/tracking-events';

@Injectable({providedIn: 'root'})
export class AnalyticsService {
  private client!: AnalyticsBrowser;
  constructor() {
    const writeKey = environment.SEGMENT_WRITE_KEY;
    if (writeKey) {
      this.client = AnalyticsBrowser.load({writeKey});
    }
  }

  async getAnonymousId(): Promise<ID> {
    const user = await this.client.user();
    return user.anonymousId();
  }

  identify(userId: string, traits: FlexDictionaryType={}) {
    if (this.client) {
      this.client.identify(userId, {...traits});
      return;
    }
    console.info('AnalyticsService: identify', userId, {...traits});
  }

  alias(aliasId: string) {
    if (this.client) {
      this.client.alias(aliasId);
      return;
    }
    console.info('AnalyticsService: alias', aliasId);
  }

  track(event: TrackingEvents, properties: FlexDictionaryType={}) {
    if (this.client) {
      this.client.track(event as string, {...properties});
      return;
    }
    console.info('AnalyticsService: track', event, {...properties});
  }
}

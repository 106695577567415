// Browser Only Utils
// Utility function to check if the current context is inside an iframe
export function isInIframe() {
  if (typeof window === 'undefined') {
    return false;
  }

  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

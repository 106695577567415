import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {lastValueFrom} from 'rxjs';
import {StripeProduct} from '../../../types/product';
import {AnalyticsService} from '../services/analytics.service';
import {TrackingEvents} from '../../../types/analytics/tracking-events';
import {deobfuscateEmail, generateUniqueUserId, getKeyByValue, isEmailValid, isInEnum, isValidDate, isValidPhoneNumber, normalizePhoneNumber, obfuscateEmail, parseQueryString, prependPlusIfCountryCodeExists} from '../../../utils';
import {LocalStorageService} from '../services/local-storage.service';
import {CallbackQueryParam} from 'src/types/callback';
import {isInIframe} from '../utils';
import {FlexDictionaryType} from '../../../types';
import {NewsletterStep} from '../../../types/newsletter';


const STEP_OPEN_WHEN_UREF_NOT_AVAILABLE: NewsletterStep[] = [NewsletterStep.EnterEmail];
const STEP_OPEN_REQUIRES_UREF: NewsletterStep[] = [NewsletterStep.EmailConfirmationUserInfo, NewsletterStep.EmailConfirmationSuccess];

const STEP_SHOULD_ALWAYS_OPEN: NewsletterStep[] = [NewsletterStep.SuperSupporterSignUp];

@Component({
  selector: 'newsletter-signup',
  templateUrl: './newsletter-signup.component.html',
})
export class NewsletterSignUpComponent implements OnInit {
  signUpStep: NewsletterStep = NewsletterStep.EnterEmail;
  isLoading = false;
  isVisible = false;

  inputTextTarget: HTMLInputElement | null = null;
  inputDateTarget: HTMLInputElement | null = null;
  inputPhoneNumberTarget: HTMLInputElement | null = null;
  isCheckboxChecked = false;
  userId = '';
  email ='';
  userRefId = '';


  componentProps =
    {
      variants: {
        step: this.signUpStep,
      },
      submitButton: {
        props: {
          loading: false,
          isDisabled: false,
          onClick: () => {
            this.handleOnSubmit();
          },
        },
      },
      superSupporterButton: {
        props: {
          onClick: () => {
            this.handleOnSuperSupporterSubmit();
          },
        },
      },
      closeButton: {
        props: {
          isDisabled: false,
          onClick: () => {
            this.handleOnClose();
          },
        },
      },
      textInput: {
        props: {
          onChange: (e: any) => this.handleTextInputOnChange(e),
          inputError: '',
          isDisabled: false,
        },
      },
      dateInput: {
        props: {
          onChange: (e: any) => this.handleDateInputOnChange(e),
          inputError: '',
          isDisabled: false,
        },
      },
      phoneNumberInput: {
        props: {
          onChange: (e: any) => this.handlePhoneNumberInputOnChange(e),
          inputError: '',
          isDisabled: false,
        },
      },
      checkbox: {
        props: {
          onChange: (e: boolean) => this.handleCheckboxOnChange(e),
          isDisabled: false,
        },
      },
    };

  constructor(private http: HttpClient, private localStorage: LocalStorageService, private analytics: AnalyticsService) {}

  ngOnInit(): void {
    this.addPostMessageListener();

    const queryParams = parseQueryString(window.top ? window.top.location.search : window.location.search);

    this.userId = queryParams[CallbackQueryParam.UserId] || this.localStorage.getItem(CallbackQueryParam.UserId);
    this.userRefId = queryParams[CallbackQueryParam.UserReferenceId] || this.localStorage.getItem(CallbackQueryParam.UserReferenceId);

    this.signUpStep = queryParams[CallbackQueryParam.NewsletterStep] || NewsletterStep.EnterEmail;

    const deObfuscatedUserRefId = deobfuscateEmail(this.userRefId);
    const isValidEmail = isEmailValid(deObfuscatedUserRefId);

    const stepShouldOpenIfUrefAvailable = STEP_OPEN_REQUIRES_UREF.includes(this.signUpStep);
    const stepShouldOpenIfUrefNotAvailable = STEP_OPEN_WHEN_UREF_NOT_AVAILABLE.includes(this.signUpStep);
    const stepShouldAlwaysOpen = STEP_SHOULD_ALWAYS_OPEN.includes(this.signUpStep);
    // If we don't have a valid email
    // Reset step to Enter Email
    if (!isValidEmail && stepShouldOpenIfUrefAvailable) {
      this.signUpStep = NewsletterStep.EnterEmail;
    } else {
      this.email = deObfuscatedUserRefId;
      this.analytics.identify(this.email);
      this.localStorage.setItem(CallbackQueryParam.UserId, this.userId);
      this.localStorage.setItem(CallbackQueryParam.UserReferenceId, this.userRefId);
    }

    const shouldShowModal =
   (stepShouldOpenIfUrefAvailable && !!this.userRefId) || (stepShouldOpenIfUrefNotAvailable && !this.userRefId) || stepShouldAlwaysOpen;

    this.setModalStep(this.signUpStep);

    if (shouldShowModal) {
      this.showModal();

      if (this.signUpStep === NewsletterStep.EmailConfirmationSuccess) {
        const step = getKeyByValue(NewsletterStep, this.signUpStep);
        this.analytics.identify(this.email, {emailVerified: true});
        this.analytics.track(TrackingEvents.NewsletterSignupEmailConfirmedSuccess, {step});
      }
    } else {
      this.hideModal();
    }
  }

  private addPostMessageListener() {
    if (window) {
      window.addEventListener('message', (event) => {
        // Handle the message
        if (event && event.data && event.data.action === 'reset') {
          const eventData = event.data;
          const resetToStep = isInEnum(eventData[CallbackQueryParam.NewsletterStep], NewsletterStep) ? eventData[CallbackQueryParam.NewsletterStep] : null;
          this.resetModal(resetToStep);
        }
      });
    }
  }

  resetModal(resetToStep?: NewsletterStep) {
    this.email = '';
    this.inputTextTarget = null;
    this.inputDateTarget = null;
    this.inputPhoneNumberTarget = null;
    this.isCheckboxChecked = false;
    this.userRefId = '';
    this.signUpStep = resetToStep || NewsletterStep.EnterEmail;
    this.setTextInputError('');
    this.setButtonLoading(false);
    this.setModalStep(this.signUpStep);
    this.showModal();
  }

  setModalStep(step: NewsletterStep) {
    this.componentProps = {...this.componentProps,
      variants: {
        step: step,
      },
    };
  }

  setTextInputError(inputError: string) {
    this.componentProps = {...this.componentProps,
      textInput: {
        ...this.componentProps.textInput,
        props: {
          ...this.componentProps.textInput.props,
          inputError,
        },
      },
    };
  }

  setDateInputError(inputError: string) {
    this.componentProps = {...this.componentProps,
      dateInput: {
        ...this.componentProps.dateInput,
        props: {
          ...this.componentProps.dateInput.props,
          inputError,
        },
      },
    };
  }

  setPhoneNumberInputError(inputError: string) {
    this.componentProps = {...this.componentProps,
      phoneNumberInput: {
        ...this.componentProps.phoneNumberInput,
        props: {
          ...this.componentProps.dateInput.props,
          inputError,
        },
      },
    };
  }

  setButtonLoading(isLoading: boolean) {
    this.isLoading = isLoading;
    this.componentProps = {...this.componentProps,
      submitButton: {
        ...this.componentProps.submitButton,
        props: {
          ...this.componentProps.submitButton.props,
          loading: this.isLoading,
          isDisabled: this.isLoading,
        },
      },
      closeButton: {
        ...this.componentProps.closeButton,
        props: {
          ...this.componentProps.closeButton.props,
          isDisabled: this.isLoading,
        },
      },
    };
  }

  showModal() {
    const isLoadedInIframe = isInIframe();
    const step = getKeyByValue(NewsletterStep, this.signUpStep);
    this.analytics.track(TrackingEvents.NewsletterSignupModalOpened, {step, isLoadedInIframe});
    if (isLoadedInIframe) {
      window.parent.postMessage('show', '*');
    }
    this.isVisible = true;
  }

  hideModal() {
    const isLoadedInIframe = isInIframe();

    const step = getKeyByValue(NewsletterStep, this.signUpStep);
    this.analytics.track(TrackingEvents.NewsletterSignupModalClosed, {step, isLoadedInIframe});

    if (isLoadedInIframe) {
      window.parent.postMessage('hide', '*');
    }
    this.isVisible = false;
  }

  async handleOnSubmit() {
    let textInputError = '';
    let dateInputError = '';
    let phoneNumberInputError = '';

    const step = getKeyByValue(NewsletterStep, this.signUpStep);

    const inputText= this.inputTextTarget ? this.inputTextTarget.value : '';
    const inputDate= this.inputDateTarget ? this.inputDateTarget.value : '';
    const inputPhoneNumber = this.inputPhoneNumberTarget ? this.inputPhoneNumberTarget.value : '';
    const normalizedPhoneNumber = normalizePhoneNumber(inputPhoneNumber);

    if (this.signUpStep === NewsletterStep.EnterEmail) {
      if (!isEmailValid(inputText)) {
        textInputError = 'Please enter a valid email';
        this.analytics.track(TrackingEvents.NewsletterSignupEnterEmailInvalidEmail);
      } else {
        this.setButtonLoading(true);
        this.email = inputText.trim();
        const id = generateUniqueUserId(this.email);
        const uref = obfuscateEmail(this.email);
        this.localStorage.setItem(CallbackQueryParam.UserId, id);
        this.localStorage.setItem(CallbackQueryParam.UserReferenceId, uref);
        this.analytics.identify(id, {id, email: this.email, uref});
        this.analytics.alias(this.email);
        this.analytics.track(TrackingEvents.NewsletterSignupModalSubscriptionSuccess, {step});
        this.signUpStep = NewsletterStep.SubscriptionSuccess;
      }
    }

    if (this.signUpStep === NewsletterStep.EmailConfirmationUserInfo) {
      if (!inputText || !inputText.trim()) {
        textInputError = 'Please enter your name';
        this.analytics.track(TrackingEvents.NewsletterSignupEmailConfirmationUserInfoInvalidName);
      } else if (!isValidDate(inputDate)) {
        dateInputError = 'Please enter your birthday';
        this.analytics.track(TrackingEvents.NewsletterSignupEmailConfirmationUserInfoInvalidBirthday);
      } else if (this.isCheckboxChecked && !isValidPhoneNumber(normalizedPhoneNumber)) {
        phoneNumberInputError = 'Please enter a valid phone number';
        this.analytics.track(TrackingEvents.NewsletterSignupEmailConfirmationUserInfoInvalidPhoneNumber);
      } else {
        const name = inputText.trim();
        const birthdateString = inputDate.trim();
        const birthdateDate = new Date(birthdateString);
        const birthdate = birthdateDate.toISOString();
        const birthdateUnix = birthdateDate.getTime();
        const birthdateMonth = birthdateDate.getMonth();
        const birthdateYear = birthdateDate.getFullYear();

        const trackData: FlexDictionaryType = {name, birthdate, birthdateUnix, birthdateMonth, birthdateYear, emailVerified: true};

        if (this.isCheckboxChecked && normalizedPhoneNumber) {
          trackData['smsNumber'] = prependPlusIfCountryCodeExists(normalizedPhoneNumber);
        }
        this.analytics.identify(this.email, trackData);
        this.analytics.track(TrackingEvents.NewsletterSignupModalUserInfoSubmitted, {step});
        this.signUpStep = NewsletterStep.EmailConfirmationSuccess;
      }
    }


    this.setButtonLoading(false);

    this.setModalStep(this.signUpStep);

    this.setTextInputError(textInputError);
    this.setDateInputError(dateInputError);
    this.setPhoneNumberInputError(phoneNumberInputError);
  }

  async handleOnSuperSupporterSubmit() {
    const anonymousId = await this.analytics.getAnonymousId();
    const request$ = this.http.post<{checkoutUrl: string}>(environment.apiUrlBase + '/stripe/v1/create-checkout-session', {email: this.email, product: StripeProduct.SuperSupporter, anonymousId});
    const {checkoutUrl} = await lastValueFrom(request$);
    if (window.top) {
      window.top.location.href = checkoutUrl;
    } else {
      window.location.href = checkoutUrl;
    }
  }

  handleOnClose() {
    this.hideModal();
    this.signUpStep = NewsletterStep.EnterEmail;
    this.setModalStep(this.signUpStep);
  }

  handleTextInputOnChange(input: any
  ) {
    if (this.isLoading || this.inputTextTarget) {
      return;
    }
    this.inputTextTarget = input.target;
    this.setTextInputError('');
  }

  handleDateInputOnChange(input: any
  ) {
    if (this.isLoading || this.inputDateTarget) {
      return;
    }
    this.inputDateTarget = input.target;
    this.setDateInputError('');
  }

  handlePhoneNumberInputOnChange(input: any
  ) {
    if (this.isLoading || this.inputPhoneNumberTarget) {
      return;
    }
    this.inputPhoneNumberTarget = input.target;
    this.setPhoneNumberInputError('');
  }

  handleCheckboxOnChange(isChecked: boolean
  ) {
    if (this.signUpStep === NewsletterStep.EmailConfirmationUserInfo) {
      if (isChecked) {
        this.analytics.track(TrackingEvents.NewsletterSignupEmailConfirmationUserInfoTextUpdatesChecked);
      } else {
        this.analytics.track(TrackingEvents.NewsletterSignupEmailConfirmationUserInfoTextUpdatesUnchecked);
      }
    }
    if (this.isLoading) {
      return;
    }
    this.isCheckboxChecked = isChecked;
  }
}

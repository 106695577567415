import {initPlasmicLoader} from '@plasmicapp/loader-angular';
import {environment} from './environments/environment';
export const PLASMIC = initPlasmicLoader({
  projects: [
    {
      id: environment.PLASMIC_PROJECT_ID, // ID of a project you are using
      token: environment.PLASMIC_API_KEY, // API token for that project
    },
  ],
  // Fetches the latest revisions, whether or not they were unpublished!
  // Disable for production to ensure you render only published changes.
  preview: !environment.production,
});

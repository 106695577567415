import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {PlasmicModule} from '@plasmicapp/loader-angular';
import {NewsletterSignUpComponent} from './newsletter-signup/newsletter-signup.component';
import {HealthCheckComponent} from './health-check/health-check.component';

@NgModule({
  declarations: [
    AppComponent,
    HealthCheckComponent,
    NewsletterSignUpComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    HttpClientModule,
    AppRoutingModule,
    PlasmicModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }

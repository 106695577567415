import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NewsletterSignUpComponent} from './newsletter-signup/newsletter-signup.component';
import {HealthCheckComponent} from './health-check/health-check.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/health',
    pathMatch: 'full',
  },
  {
    path: 'health',
    component: HealthCheckComponent,
  },
  {
    path: 'newsletter-signup',
    component: NewsletterSignUpComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
